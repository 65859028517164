<template>
  <v-app>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="100"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-navigation-drawer
      app
      v-if="getUser != null"
      v-model="drawer"
      stateless
      fixed
      clipped
    >
      <v-toolbar flat class="transparent">
        <v-list class="pa-0">
          <v-list-item avatar>
            <v-list-item-avatar color="white">
              <!-- <img
                class="responsive"
                src="../../assets/log.png"
                alt="Logo telemon"
              /> -->
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ email }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-toolbar>

      <v-list>
        <v-divider class="mb-3"></v-divider>

        <v-list-item
          v-for="(child, idx) in drawerOptions"
          :key="idx"
          :to="child.path"
        >
          <v-list-item-action>
            <v-icon>{{ child.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-3"></v-divider>
        <v-list-item @click="signOut">
          <v-list-item-action>
            <v-icon>directions_run</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar v-if="getUser != null" app fixed clipped-left>
      <v-btn icon @click.stop="drawer = !drawer">
        <v-icon color="black">menu</v-icon>
      </v-btn>
      <v-toolbar-title class=" title"
        >SADAII | Panel de administración</v-toolbar-title
      >
    </v-app-bar>
    <v-content>
      <v-container>
        <router-view
          v-on:show-snackbar="showSnackbar"
          @show-Overlay="showOverlay"
        />
      </v-container>
      <v-snackbar v-model="snackbar.visible" :timeout="3000">
        <span>{{ snackbar.text }}</span>
        <v-btn @click.native="snackbar.visible = false" dark text>Cerrar</v-btn>
      </v-snackbar>
    </v-content>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Admin",
  data: () => ({
    isLogged: false,
    drawer: null,
    overlay: false,
    drawerOptions: [
      {
        title: "Inicio",
        path: "/home",
        icon: "home",
      },
      {
        title: "Clientes",
        path: "/client",
        icon: "person_search",
      },
      {
        title: "Pedidos en Proceso",
        path: "/requests",
        icon: "timer",
      },
      {
        title: "Pedidos Completados",
        path: "/requestsCompleted",
        icon: "moped",
      },
      {
        title: "Productos",
        path: "/product",
        icon: "inventory",
      },
    ],
    snackbar: {
      visible: false,
      text: "",
    },
  }),

  computed: {
    ...mapGetters(["userAdmin"]),
    getUser() {
      return this.userAdmin;
    },
    email() {
      const emailUser = this.userAdmin.email;
      return emailUser ? emailUser : false;
    },
  },
  methods: {
    ...mapActions(["logoutUserAdminFromFirebase"]),
    showSnackbar(message) {
      this.snackbar.visible = true;
      this.snackbar.text = message;
    },
    showOverlay(overlay) {
      this.overlay = overlay;
    },
    signOut() {
      this.logoutUserAdminFromFirebase();
    },
  },
};
</script>
<style lang="css">
.title {
  font-size: 2em;
  font-weight: bold;
  color: black;
}
</style>
